import React, { PureComponent } from "react";
import "./styles/social.css";
import { SocialIcon } from "react-social-icons";
import Fade from "react-reveal/Fade";
class Actions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="socials"
        style={{
          backgroundColor: this.props.TextColor
        }}
      >
        {this.props.data.settings.social_links.map((social, i) => {
          return (
            <Fade bottom key={i}>
              {social.value.indexOf("instagram") > -1 ? (
                <div>
                  <a
                    href={social.value}
                    terget="_blank"
                    className="social-icon-insta-custom"
                    style={{
                      display: "inline-block",
                      width: "64px",
                      height: "64px",
                      margin: "1rem",
                      borderRadius: "64px",
                      position: "relative",
                      overflow: "hidden",
                      verticalAlign: "middle",
                      backgroundColor: "rgba(0, 121, 147, 0.92)",
                      color: "#fff"
                    }}
                    aria-label="facebook"
                  >
                    <svg
                      viewBox="0 0 64 64"
                      style={{
                        width: "93px",
                        position: "absolute",
                        fill: "#fff",
                        top: "14px",
                        left: "14px"
                      }}
                      className="social-svg"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                    </svg>
                  </a>
                </div>
              ) : (
                <SocialIcon
                  bgColor="rgba(0, 121, 147, 0.92)"
                  url={social.value}
                />
              )}
            </Fade>
          );
        })}
      </div>
    );
  }
}

export default Actions;
