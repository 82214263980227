import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import Layout from "../Layout";
import ArticlesFeed from "../cockpitComponents/ArticlesFeed";
import TagManager from "react-gtm-module";

import { Link } from "react-router-dom";

import Fade from "react-reveal/Fade";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import "./styles/articles.css";
var d = new Date();
var articleDate;

class Articles extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: [],
      limit: 10,
      skip: 0,
      noMore: false
    };
  }

  async fetchData() {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}collections/get/blog`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": process.env.REACT_APP_TOKEN
        },
        body: JSON.stringify({
          filter: {
            published: true,
            $or: [
              { title: { $regex: this.state.searchterm } },
              { content: { $regex: this.state.searchterm } }
            ]
          },
          lang: this.props.match.params.locale,
          sort: { date: -1 },
          skip: 0,
          limit: this.state.limit
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();

    this.setState({
      data: jsonData.entries,
      noMore:
        jsonData.total <= this.state.skip + this.state.limit ? true : false
    });
  }

  async fetchMore() {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}collections/get/blog`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": process.env.REACT_APP_TOKEN
        },
        body: JSON.stringify({
          filter: {
            published: true,

            $or: [
              { title: { $regex: this.state.searchterm } },
              { content: { $regex: this.state.searchterm } }
            ]
          },
          lang: this.props.match.params.locale,
          sort: { date: -1 },
          skip: this.state.limit + this.state.skip,
          limit: this.state.limit
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();
    var newdata = this.state.data.concat(jsonData.entries);
    this.setState({
      data: newdata,
      skip: this.state.skip + this.state.limit,
      noMore:
        jsonData.total <= this.state.skip + this.state.limit ? true : false
    });
    console.log(jsonData);
  }

  async componentDidMount() {
    window.scroll(0, 0);
    await this.setState({
      fetching: true
    });

    await this.fetchData();

    this.setState({
      fetching: false
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.match.url !== prevProps.match.url) {
      window.scroll(0, 0);
      await this.setState({
        fetching: true
      });
      await this.fetchData();

      this.setState({
        fetching: false
      });
    }
  }

  render() {
    if (!this.state.fetching) {
      const tagManagerArgs = {
        dataLayer: {
          page: "news"
        },
        dataLayerName: "PageDataLayer"
      };
      TagManager.dataLayer(tagManagerArgs);
    }
    return (
      <div className="articles">
        {!this.state.fetching ? (
          <Helmet>
            <html
              lang={
                this.props.match.params.locale
                  ? this.props.match.params.locale
                  : "it"
              }
            />
            <meta charSet="utf-8" />
            <title>Blog - {process.env.REACT_APP_NAME}</title>
            <link rel="canonical" href={window.location.href} />
            <meta
              property="og:title"
              content={`Home - ${process.env.REACT_APP_NAME}`}
            />
          </Helmet>
        ) : null}
        <Layout
          url={this.props.match.url}
          locale={this.props.match.params.locale}
        >
          <div className="articlesContainer">
            <div className="articlesFeed">
              <div
                className="articlesFeedContainer"
                style={{
                  backgroundColor: this.props.bgColor,
                  position: "relative"
                }}
              >
                <Fade bottom cascade>
                  <div className="articlesFeedContainerInner">
                    {!this.state.fetching
                      ? this.state.data.map((article, i) => {
                          const splittedDate = article.date.split("-");

                          return (
                            <Link
                              className="articleFeedCard"
                              key={i}
                              title={article.title}
                              to={
                                !this.props.locale
                                  ? `/blog/${article.url_handle}`
                                  : `/${this.props.locale}/blog/${
                                      article.url_handle
                                    }`
                              }
                            >
                              <div
                                style={{
                                  backgroundImage: `url(${
                                    process.env.REACT_APP_API_ENDPOINT
                                  }cockpit/image?token=${
                                    process.env.REACT_APP_TOKEN
                                  }&src=${
                                    article.image._id
                                  }&w=1920&h=1440&q=50&o=true)`
                                }}
                              >
                                <div
                                  className="articleDate"
                                  style={{ color: "#ccc" }}
                                >
                                  <br />
                                  {splittedDate[2]}-{splittedDate[1]}-
                                  {splittedDate[0]}
                                  <br />
                                </div>
                                <div className="articleTitle">
                                  {article.title}
                                </div>
                                <div className="articleExcerpt">
                                  <br />
                                  {article.excerpt}
                                  <br />
                                  <br />
                                </div>
                              </div>
                            </Link>
                          );
                        })
                      : null}
                  </div>
                </Fade>
                {!this.state.noMore ? (
                  <Button
                    onClick={() => {
                      this.fetchMore();
                    }}
                    variant="contained"
                    color="primary"
                  >
                    More
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export default Articles;
