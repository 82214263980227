import React, { PureComponent } from "react";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";

var current = null;

class DrawerList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handelize(str) {
    return str
      .toLowerCase()
      .replace(/[^\w\u00C0-\u024f]+/g, "-")
      .replace(/^-+|-+$/g, "");
  }

  handleClick = e => {
    this.setState({
      [current]: false,
      [e]: !this.state[e]
    });
    current = e;
  };

  render() {
    var currentUrl = this.props.url.split("/");
    if (currentUrl[1].length === 2) {
      currentUrl.splice(0, 2);
      currentUrl = currentUrl.join("/");
    } else {
      currentUrl.splice(0, 1);
      currentUrl = currentUrl.join("/");
    }
    return (
      <div className="drawerlist">
        <List>
          <ListItem
            button
            key="Home"
            component={Link}
            to={!this.props.locale ? `/` : `/${this.props.locale}/`}
          >
            <ListItemText>
              <strong>HOME</strong>
            </ListItemText>
          </ListItem>
          <Divider />

          {this.props.data.map((item1, i1) => {
            return item1.field.name === "parent" ? (
              <div key={i1}>
                <ListItem
                  button
                  onClick={this.handleClick.bind(this, item1.value.label)}
                >
                  <ListItemText>
                    <span
                      dangerouslySetInnerHTML={{ __html: item1.value.label }}
                    />
                  </ListItemText>

                  {this.state[item1.value.label] ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItem>
                <Collapse
                  in={this.state[item1.value.label]}
                  timeout="auto"
                  unmountOnExit
                >
                  {item1.value.links
                    ? item1.value.links.map((item2, i2) => {
                        return item2.field.name === "page" ? (
                          <ListItem
                            button
                            key={i2}
                            component={Link}
                            to={
                              !this.props.locale
                                ? `/${item1.value.handle}/${
                                    item2.value.link.url_handle
                                  }`
                                : `/${this.props.locale}/${
                                    item1.value.handle
                                  }/${item2.value.link.url_handle}`
                            }
                            style={{
                              color: this.props.textColor
                            }}
                          >
                            <ListItemText>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item2.value.label
                                }}
                              />
                            </ListItemText>
                          </ListItem>
                        ) : item2.field.name === "custom" ? (
                          item2.value.url.indexOf("/") === 0 ? (
                            <ListItem
                              button
                              key={i2}
                              component={Link}
                              to={
                                !this.props.locale
                                  ? `${item2.value.url}`
                                  : `/${this.props.locale}${item2.value.url}`
                              }
                              style={{
                                color: this.props.textColor
                              }}
                            >
                              <ListItemText>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item2.value.label
                                  }}
                                />
                              </ListItemText>
                            </ListItem>
                          ) : (
                            <ListItem
                              button
                              key={i2}
                              component="a"
                              target={item2.value.new_tab ? "_blank" : ""}
                              href={item2.value.url}
                              style={{
                                color: this.props.textColor
                              }}
                            >
                              <ListItemText>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item2.value.label
                                  }}
                                />
                              </ListItemText>
                            </ListItem>
                          )
                        ) : null;
                      })
                    : null}
                </Collapse>
              </div>
            ) : item1.field.name === "page" ? (
              <ListItem
                button
                key={i1}
                component={Link}
                to={
                  !this.props.locale
                    ? `/${item1.value.link.url_handle}`
                    : `/${this.props.locale}/${item1.value.link.url_handle}`
                }
                style={{
                  color: this.props.textColor
                }}
              >
                <ListItemText>
                  <span
                    dangerouslySetInnerHTML={{ __html: item1.value.label }}
                  />
                </ListItemText>
              </ListItem>
            ) : item1.field.name === "custom" ? (
              item1.value.url.indexOf("/") === 0 ? (
                <ListItem
                  button
                  key={i1}
                  component={Link}
                  to={
                    !this.props.locale
                      ? `${item1.value.url}`
                      : `/${this.props.locale}${item1.value.url}`
                  }
                  style={{
                    color: this.props.textColor
                  }}
                >
                  <ListItemText>
                    <span
                      dangerouslySetInnerHTML={{ __html: item1.value.label }}
                    />
                  </ListItemText>
                </ListItem>
              ) : (
                <ListItem
                  button
                  key={i1}
                  component="a"
                  target={item1.value.new_tab ? "_blank" : ""}
                  href={item1.value.url}
                  style={{
                    color: this.props.textColor
                  }}
                >
                  <ListItemText>
                    <span
                      dangerouslySetInnerHTML={{ __html: item1.value.label }}
                    />
                  </ListItemText>
                </ListItem>
              )
            ) : null;
          })}
        </List>
        <div style={{ height: "2rem" }} />
        <ListItem
          className={
            this.props.locale === undefined ? "selected langBtn" : "langBtn"
          }
          key="Italiano"
          color="inherit"
          component={Link}
          to={`/${currentUrl}`}
          title="Italiano"
          style={{ width: "1rem", display: "none" }}
        >
          <strong>IT</strong>
        </ListItem>
        <ListItem
          className={
            this.props.locale === "de" ? "selected langBtn" : "langBtn"
          }
          key="Deutsch"
          color="inherit"
          component={Link}
          to={`/de/${currentUrl}`}
          title="Deutsch"
          style={{ width: "1rem", display: "none" }}
        >
          <strong>DE</strong>
        </ListItem>
        <ListItem
          className={
            this.props.locale === "fr" ? "selected langBtn" : "langBtn"
          }
          key="Francais"
          color="inherit"
          component={Link}
          to={`/fr/${currentUrl}`}
          title="Francais"
          style={{ width: "1rem", display: "none" }}
        >
          <strong>FR</strong>
        </ListItem>
        <ListItem
          className={
            this.props.locale === "en" ? "selected langBtn" : "langBtn"
          }
          key="English"
          color="inherit"
          component={Link}
          to={`/en/${currentUrl}`}
          title="English"
          style={{ width: "1rem", display: "none" }}
        >
          <strong>EN</strong>
        </ListItem>
      </div>
    );
  }
}

export default DrawerList;
