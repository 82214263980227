import React, { PureComponent } from "react";

import HeroSlider from "./cockpitComponents/HeroSlider";
import BenneSlider from "./cockpitComponents/BenneSlider";
import Content from "./cockpitComponents/Content";
import Title from "./cockpitComponents/Title";
import ImageAndText from "./cockpitComponents/ImageAndText";
import ImageLinks from "./cockpitComponents/ImageLinks";
import IconLinks from "./cockpitComponents/IconLinks";
import Info from "./cockpitComponents/Info";
import Counters from "./cockpitComponents/Counters";
import Actions from "./cockpitComponents/Actions";
import Form from "./cockpitComponents/Form";
import CustomForm from "./cockpitComponents/CustomForm";
import FormMailchimp from "./cockpitComponents/FormMailchimp";
import Tabs from "./cockpitComponents/Tabs";
import Social from "./cockpitComponents/Social";
import MapComponent from "./cockpitComponents/Map";
import GridGallery from "./cockpitComponents/GridGallery";
import BottomNav from "./cockpitComponents/BottomNav";
import ArticlesFeed from "./cockpitComponents/ArticlesFeed";

var d = new Date();

class CockpitComponentsDispatcher extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="cockptComponents">
        {this.props.data.components
          ? this.props.data.components.map((component, i) => {
              var componentVisible = true;
              var componentDateFrom;
              var componentDateTo;

              if (component.settings.date_from) {
                componentDateFrom = new Date(
                  component.settings.date_from
                ).getTime();
              } else {
                componentDateFrom = d.getTime();
              }

              if (component.settings.date_to) {
                componentDateTo = new Date(
                  component.settings.date_to
                ).getTime();
              } else {
                componentDateTo = 9999999999999;
              }

              if (componentDateFrom > d) {
                componentVisible = false;
              } else {
                if (d > componentDateTo) {
                  componentVisible = false;
                }
              }

              if (componentVisible) {
                if (component.component === "hero_slider") {
                  return (
                    <HeroSlider
                      bgColor={this.props.data.bg_color}
                      key={i}
                      data={component}
                      locale={this.props.locale}
                    />
                  );
                }

                if (component.component === "benne_slider") {
                  return (
                    <BenneSlider
                      bgColor={this.props.data.bg_color}
                      key={i}
                      data={component}
                      locale={this.props.locale}
                    />
                  );
                }

                if (component.component === "divider") {
                  return (
                    <div
                      className="divider"
                      key={i}
                      style={{
                        backgroundColor: this.props.data.bg_color
                      }}
                    />
                  );
                }

                if (component.component === "content") {
                  return (
                    <Content
                      key={i}
                      data={component}
                      textColor={this.props.data.text_color}
                      titleColor={this.props.data.title_color}
                      bgColor={this.props.data.bg_color}
                    />
                  );
                }
                if (component.component === "title") {
                  return (
                    <Title
                      key={i}
                      data={component}
                      textColor={this.props.data.text_color}
                      titleColor={this.props.data.title_color}
                      bgColor={this.props.data.bg_color}
                    />
                  );
                }
                if (component.component === "image_and_text") {
                  return (
                    <ImageAndText
                      key={i}
                      data={component}
                      textColor={this.props.data.text_color}
                      titleColor={this.props.data.title_color}
                      bgColor={this.props.data.bg_color}
                      locale={this.props.locale}
                    />
                  );
                }
                if (component.component === "imagelink") {
                  return (
                    <ImageLinks
                      key={i}
                      data={component}
                      locale={this.props.locale}
                    />
                  );
                }
                if (component.component === "iconlink") {
                  return (
                    <IconLinks
                      key={i}
                      data={component}
                      textColor={this.props.data.text_color}
                      titleColor={this.props.data.title_color}
                      bgColor={this.props.data.bg_color}
                      locale={this.props.locale}
                    />
                  );
                }
                if (component.component === "info") {
                  return (
                    <Info
                      key={i}
                      data={component}
                      textColor={this.props.data.text_color}
                      titleColor={this.props.data.title_color}
                      bgColor={this.props.data.bg_color}
                    />
                  );
                }

                if (component.component === "counters") {
                  return (
                    <Counters
                      key={i}
                      data={component}
                      textColor={this.props.data.text_color}
                      titleColor={this.props.data.title_color}
                      bgColor={this.props.data.bg_color}
                    />
                  );
                }
                if (component.component === "actions") {
                  return (
                    <Actions
                      key={i}
                      data={component}
                      textColor={this.props.data.text_color}
                      bgColor={this.props.data.bg_color}
                      locale={this.props.locale}
                    />
                  );
                }
                if (component.component === "form") {
                  return (
                    <Form
                      key={i}
                      data={component}
                      titleColor={this.props.data.title_color}
                      textColor={this.props.data.text_color}
                      bgColor={this.props.data.bg_color}
                    />
                  );
                }
                if (component.component === "custom_form") {
                  return (
                    <CustomForm
                      key={i}
                      data={component}
                      titleColor={this.props.data.title_color}
                      textColor={this.props.data.text_color}
                      bgColor={this.props.data.bg_color}
                    />
                  );
                }
                if (component.component === "form_mailchimp") {
                  return (
                    <FormMailchimp
                      key={i}
                      data={component}
                      titleColor={this.props.data.title_color}
                      textColor={this.props.data.text_color}
                      bgColor={this.props.data.bg_color}
                    />
                  );
                }
                if (component.component === "tabs") {
                  return (
                    <Tabs
                      key={i}
                      data={component}
                      titleColor={this.props.data.title_color}
                      textColor={this.props.data.text_color}
                      bgColor={this.props.data.bg_color}
                    />
                  );
                }
                if (component.component === "social") {
                  return (
                    <Social
                      key={i}
                      data={component}
                      titleColor={this.props.data.title_color}
                      textColor={this.props.data.text_color}
                      bgColor={this.props.data.bg_color}
                    />
                  );
                }
                if (component.component === "grid_gallery") {
                  return (
                    <GridGallery
                      key={i}
                      data={component}
                      bgColor={this.props.data.bg_color}
                    />
                  );
                }
                if (component.component === "map") {
                  return (
                    <MapComponent
                      key={i}
                      data={component}
                      bgColor={this.props.data.bg_color}
                      textColor={this.props.data.text_color}
                    />
                  );
                }
                if (component.component === "bottom_navigation") {
                  return (
                    <BottomNav
                      key={i}
                      data={component}
                      textColor={this.props.data.text_color}
                      bgColor={this.props.data.bg_color}
                    />
                  );
                }
                if (component.component === "articles_feed") {
                  return (
                    <ArticlesFeed
                      key={i}
                      data={component}
                      limit={component.settings.n}
                      textColor={this.props.data.text_color}
                      bgColor={this.props.data.bg_color}
                      locale={this.props.locale}
                    />
                  );
                }
              }
            })
          : null}
      </div>
    );
  }
}

export default CockpitComponentsDispatcher;
