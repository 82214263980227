import React, { Component } from "react";

import Swiper from "react-id-swiper";

class BenneSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    console.log(this.props.data.settings);
    const params = {
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true
      },
      navigation: {
        nextEl: ".swiper-button-next.swiper-button-white",
        prevEl: ".swiper-button-prev.swiper-button-white"
      },
      spaceBetween: 0,

      //slidesPerView: 1,
      loop: false,
      autoplay: {
        delay: 6000
      },
      effect: null
    };

    return (
      <Swiper {...params}>
        {this.props.data.settings.Benne.map((item, i) => {
          return (
            <div className="benneSlider">
              <img
                src={`${process.env.REACT_APP_STORAGE}${item.value.image.path}`}
              />
            </div>
          );
        })}
      </Swiper>
    );
  }
}

export default BenneSlider;
