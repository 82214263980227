import React, { PureComponent } from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import "./styles/privacyPopUp.css";

class PrivacyPopUp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { fetching: true };
  }

  async fetchData() {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}singletons/get/privacypolicy`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": process.env.REACT_APP_TOKEN
        },
        body: JSON.stringify({
          lang: this.props.locale
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();

    this.setState({
      data: jsonData
    });
  }

  async componentDidMount() {
    const cachedData = sessionStorage.getItem(
      `privacyPopUp_${this.props.locale}`
    );
    if (!cachedData) {
      this.setState({
        fetching: true
      });
      await this.fetchData();
      this.setState({
        fetching: false
      });

      sessionStorage.setItem(`privacyPopUp_${this.props.locale}`, true);
    } else {
      this.setState({
        fetching: true
      });
    }
  }

  itsokay() {
    this.setState({
      itsokay: true
    });
  }

  render() {
    if (!this.state.fetching && !this.state.itsokay) {
      return (
        <div
          className="privacyPopUp"
          style={{
            backgroundColor: this.state.data.popup_bg_color,
            color: this.state.data.popup_text_color
          }}
        >
          <div className="privacyPopUpInner">
            <div className="text">{this.state.data.popup_content}</div>

            <div className="buttons">
              <Button
                component={Link}
                style={{
                  backgroundColor: this.state.data.popup_bg_color,
                  color: this.state.data.popup_text_color,
                  borderColor: this.state.data.popup_text_color
                }}
                variant="outlined"
                to={
                  !this.props.locale
                    ? `/privacy-policy`
                    : `/${this.props.locale}/privacy-policy`
                }
              >
                {this.state.data.title}
              </Button>
              <Button
                onClick={this.itsokay.bind(this)}
                style={{
                  backgroundColor: this.state.data.popup_text_color,
                  color: this.state.data.popup_bg_color
                }}
                variant="contained"
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default PrivacyPopUp;
