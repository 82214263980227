import React, { PureComponent } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CockpitSettingsWrapper from "./CockpitSettingsWrapper";
import styled from "styled-components";
import "./styles/customForm.css";
const ReactMarkdown = require("react-markdown/with-html");

var mailObj = {};
class CustomForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      success: false,
      error: false
    };
  }

  handleField = name => event => {
    event.preventDefault();
    mailObj[name] = event.target.value;
  };
  handleChange = name => event => {
    mailObj[name] = event.target.checked;
  };
  replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, "g"), replace);
  }
  async handleSubmit(e) {
    mailObj["Da Pagina"] = document.title;
    e.preventDefault();
    this.setState({
      loading: true
    });

    let sendMail = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}forms/submit/` +
        this.props.data.settings.form_name,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": process.env.REACT_APP_TOKEN
        },
        body: JSON.stringify({
          form: mailObj
        })
      }
    );

    if (!sendMail.ok) {
      this.setState({
        loading: false,
        submitted: true,
        error: true
      });
      return;
    }

    this.setState({
      loading: false,
      submitted: true,
      success: true
    });
  }
  componentDidMount() {}

  render() {
    const StyledTextField = styled(TextField)`
      label {
        color: ${this.props.textColor};
        opacity: 1;
      }
      label.focused {
        color: ${this.props.textColor};
        opacity: 1;
      }
      .MuiInputBase-input {
        color: ${this.props.textColor};
      }
      .MuiOutlinedInput-root {
        fieldset {
          border-color: ${this.props.textColor};
          opacity: 0.6;
          color: ${this.props.textColor};
        }
        &:hover fieldset {
          border-color: ${this.props.textColor};
          opacity: 1;
        }
        &.Mui-focused fieldset {
          color: ${this.props.textColor};
          opacity: 1;
        }
      }
    `;
    return (
      <div
        style={{
          backgroundColor: this.props.bgColor,
          color: this.props.textColor,
          position: "relative"
        }}
      >
        <CockpitSettingsWrapper data={this.props.data}>
          <div className="customFormContainer">
            <div className="customFormInner">
              <div className="x50 s-x100 content">
                <ReactMarkdown
                  source={this.props.data.settings.html}
                  escapeHtml={false}
                />
              </div>
              <div className="x50 s-x100">
                {!this.state.submitted ? (
                  <form
                    className="customFormFields"
                    onSubmit={this.handleSubmit.bind(this)}
                  >
                    <div className="customFormField x100">
                      <StyledTextField
                        style={{
                          color: this.props.textColor
                        }}
                        className="x100"
                        id="email"
                        name="Email"
                        label="Email"
                        onChange={this.handleField("Email")}
                        margin="normal"
                        variant="outlined"
                      />
                    </div>
                    <div className="customFormField x50 xs-x100">
                      <StyledTextField
                        style={{
                          color: this.props.textColor
                        }}
                        className="x100"
                        id="nome"
                        name="Nome"
                        label="Nome"
                        onChange={this.handleField("Nome")}
                        margin="normal"
                        variant="outlined"
                      />
                    </div>
                    <div className="customFormField x50 xs-x100">
                      <StyledTextField
                        style={{
                          color: this.props.textColor
                        }}
                        className="x100"
                        id="cognome"
                        name="Cognome"
                        label="Cognome"
                        onChange={this.handleField("Cognome")}
                        margin="normal"
                        variant="outlined"
                      />
                    </div>

                    <div className="customFormField x100">
                      <StyledTextField
                        style={{
                          color: this.props.textColor
                        }}
                        className="x100"
                        select
                        id="info"
                        name="Info"
                        label="desidero Informazioni riguardo a:"
                        onChange={this.handleField("Info riguardo")}
                        margin="normal"
                        variant="outlined"
                        color="inherit"
                        SelectProps={{
                          native: true
                        }}
                      >
                        <option disabled selected />
                        <option key="Automezzi">Automezzi</option>
                        <option key="Benne Cassoni e Presse">
                          Benne Cassoni e Presse
                        </option>
                        <option key="Macchinari">Macchinari</option>
                        <option key="Pesa Pubblica">Pesa Pubblica</option>
                        <option key="Separazione e Riciclaggio Materiali">
                          Separazione e Riciclaggio Materiali
                        </option>
                        <option key="Impianto di Separazione">
                          Impianto di Separazione
                        </option>
                        <option key="Noleggi">Noleggi</option>
                        <option key="Altro">Altro</option>
                      </StyledTextField>
                    </div>

                    <div className="customFormField x100">
                      <StyledTextField
                        style={{
                          color: this.props.textColor
                        }}
                        className="x100 textarea"
                        id="messaggio"
                        name="Messaggio"
                        label="Messaggio"
                        onChange={this.handleField("Messaggio")}
                        margin="normal"
                        variant="outlined"
                        multiline
                      />
                    </div>
                    <div className="customFormField x100">
                      <Button
                        className="customFormSend"
                        style={{
                          color: this.props.bgColor,
                          backgroundColor: this.props.textColor
                        }}
                        variant="contained"
                        type="submit"
                      >
                        Invia
                      </Button>
                    </div>
                  </form>
                ) : (
                  <div className="customFormSuccess" />
                )}
              </div>
            </div>
          </div>
        </CockpitSettingsWrapper>
      </div>
    );
  }
}

export default CustomForm;
