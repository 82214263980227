import React, { PureComponent } from "react";
import Fade from "react-reveal/Fade";
import { useSpring, animated } from "react-spring";
import Counter from "./Counter";
import "./styles/counters.css";
import CockpitSettingsWrapper from "./CockpitSettingsWrapper";

const ReactMarkdown = require("react-markdown/with-html");

class Counters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // const counters = document.getElementsByClass("tocount");
    // console.log(counters);
    return (
      <div
        className="countersContainer"
        style={{
          backgroundColor: this.props.bgColor,
          position: "realtive"
        }}
      >
        <CockpitSettingsWrapper data={this.props.data}>
          <div className="counters">
            {this.props.data.settings.counter.map((counter, i) => {
              return <Counter props={counter} />;
            })}
          </div>
        </CockpitSettingsWrapper>
      </div>
    );
  }
}

export default Counters;
