import React from "react";
import { useSpring, animated } from "react-spring";
import { Spring, config } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";
const Counter = props => {
  //console.log(props.props.value);

  return (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (
        <div className="counter">
          <Spring
            delay={200}
            config={{ tension: 10, friction: 1, clamp: true }}
            from={{ number: 0, color: "#007993" }}
            to={{
              number: isVisible ? 0 : 156,
              color: isVisible ? "#007993" : "#007993"
            }}
          >
            {values => (
              <div className="donut">
                <svg
                  viewBox="0 0 51 51"
                  stroke-width="2"
                  fill="transparent"
                  stroke={values.color}
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-dasharray={156}
                  stroke-dashoffset={values.number}
                >
                  <circle
                    transform="translate(25.500000, 25.500000) rotate(-270.000000) translate(-25.500000, -25.500000)"
                    cx="25.5"
                    cy="25.5"
                    r="24.5"
                  />
                </svg>
              </div>
            )}
          </Spring>
          <div className="number">
            {props.props.value.prefix ? props.props.value.prefix : null}

            <Spring
              config={{ tension: 10, friction: 1, clamp: true }}
              delay={200}
              from={{ number: 0 }}
              to={{
                number: isVisible ? parseInt(props.props.value.number) : 0
              }}
            >
              {({ number }) => Math.floor(number)}
            </Spring>
            {props.props.value.suffix ? props.props.value.suffix : null}
          </div>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: props.props.value.content }}
          />
        </div>
      )}
    </VisibilitySensor>
  );
};

export default Counter;
