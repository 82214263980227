import React, { PureComponent } from "react";
import Fade from "react-reveal/Fade";
import "./styles/info.css";
import CockpitSettingsWrapper from "./CockpitSettingsWrapper";

const ReactMarkdown = require("react-markdown/with-html");

class Info extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // const counters = document.getElementsByClass("tocount");
    // console.log(counters);
    return (
      <div
        className="infosContainer"
        style={{
          backgroundColor: this.props.bgColor,
          position: "realtive"
        }}
      >
        <CockpitSettingsWrapper data={this.props.data}>
          <div className="infos">
            {this.props.data.settings.info.map((info, i) => {
              return (
                <Fade bottom>
                  <div className="info" key={i}>
                    {info.value.title ? (
                      <h2
                        style={{
                          color: this.props.titleColor
                        }}
                      >
                        {info.value.title}
                      </h2>
                    ) : null}
                    <p
                      style={{
                        color: this.props.textColor
                      }}
                    >
                      <ReactMarkdown
                        source={info.value.content}
                        escapeHtml={false}
                      />
                    </p>
                  </div>
                </Fade>
              );
            })}
          </div>
        </CockpitSettingsWrapper>
      </div>
    );
  }
}

export default Info;
